export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~7],
		"/admin": [~8,[2]],
		"/admin/artikler-tiny": [~10,[2]],
		"/admin/artikler": [~9,[2]],
		"/admin/db-funktioner": [~11,[2]],
		"/admin/edit-user/[id]": [~12,[2]],
		"/admin/hashtester": [13,[2]],
		"/admin/home-beskeder": [~14,[2]],
		"/admin/ingredienser": [~15,[2]],
		"/admin/kategorier": [~16,[2]],
		"/admin/kontaktformularen": [~17,[2]],
		"/admin/marketing": [~18,[2]],
		"/admin/opskrifter": [~19,[2]],
		"/admin/opskriftfotos": [~20,[2]],
		"/admin/producenter": [~21,[2]],
		"/admin/recipeimages": [~22,[2]],
		"/admin/settings": [~23,[2]],
		"/admin/stats": [24,[2]],
		"/admin/temaer": [~25,[2]],
		"/admin/testside": [26,[2]],
		"/admin/vaegttyper": [~27,[2]],
		"/artikler/[rubrik]": [~28],
		"/bruger-ingredienser": [~29],
		"/bruger-opskrifter": [~30],
		"/favoritter": [~31],
		"/fileuploadtest": [~32],
		"/follow": [~33],
		"/indtast-opskrift": [~34,[],[3]],
		"/ingredienser/[kategori]/[[slug]]": [~37],
		"/ingrediensredigering": [~38],
		"/ingrediens/[id]/[slug]": [~36],
		"/ingr/[kategori]/[[slug]]": [~35],
		"/kategorier/[slug]/[[subkat]]": [~39],
		"/kontakter": [~41],
		"/kontakt": [~40],
		"/madfaka-gl": [~42],
		"/madfakta": [~43],
		"/mine-opskrifter": [~44],
		"/ny-opskrift/[[id]]": [~45],
		"/opskrifter/[slug]": [~47,[],[5]],
		"/opskrifter/[memberid]/[niceurl]": [~46,[],[4]],
		"/password-reset": [~48],
		"/plus-bruger": [~49],
		"/private-kategorier": [~50],
		"/privatlivsindstillinger": [~51],
		"/producenter/[slug]": [~52],
		"/profil": [~53],
		"/rediger-opskrift/[id]": [~54,[],[6]],
		"/search": [~55],
		"/signup": [~56],
		"/tema/[temanavn]": [~57]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';